import React from 'react'
import PropTypes from 'prop-types'
import * as categorieslistStyle from './categorieslist.module.scss'
import * as ctaStyles from './common/cta.module.scss'
import classNames from 'classnames'

const CategoriesList = ({ active, categories, large, onClick }) => {
    const categoriesListButtonClasses = classNames({
        [categorieslistStyle.category]: true,
        [categorieslistStyle.large]: large,
        [ctaStyles.ctaPrimary]: true,
        [ctaStyles.ctaActive]: active === null,
    })

    return categories !== null ? (
        <div className={categorieslistStyle.categorieslist}>
            <button
                className={categoriesListButtonClasses}
                onClick={() => onClick(null)}
                onKeyDown={() => onClick(null)}
            >
                Allt
            </button>

            {categories.map((category) => {
                const categoryButtonClasses = classNames({
                    [categorieslistStyle.category]: true,
                    [categorieslistStyle.large]: large,
                    [ctaStyles.ctaPrimary]: true,
                    [ctaStyles.ctaActive]: active && active.id === category.id,
                })
                return (
                    <button
                        key={category.id}
                        className={categoryButtonClasses}
                        onClick={() => onClick(category)}
                        onKeyDown={() => onClick(category)}
                    >
                        {category.title}
                    </button>
                )
            })}
        </div>
    ) : null
}

CategoriesList.propTypes = {
    active: PropTypes.object,
    categories: PropTypes.array.isRequired,
    large: PropTypes.bool,
}

export default CategoriesList
