import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import CategoriesList from '../components/categorieslist'
import Card from '../components/card'
import SectionContainer from '../components/layout/sectionContainer'

const ArticlesPage = () => {
    const [activeCategory, setCategory] = useState(null)

    const data = useStaticQuery(graphql`
        {
            allContentfulArticle(sort: { fields: [createdAt], order: DESC }) {
                edges {
                    node {
                        id
                        title
                        subtitle
                        createdAt(formatString: "D MMMM YYYY", locale: "sv")
                        tags
                        slug
                        quiz {
                            id
                        }
                        category {
                            id
                            slug
                            title
                            description {
                                description
                            }
                        }
                        image {
                            title
                            gatsbyImageData(
                                width: 848
                                height: 548
                                layout: CONSTRAINED
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    `)

    // Get all our categories from the articles
    const categories = data.allContentfulArticle.edges.reduce(
        (allCategories, article) => {
            if (article.node.category !== null) {
                if (
                    !allCategories.some(
                        (e) => e.id === article.node.category.id
                    )
                ) {
                    allCategories.push(article.node.category)
                }
            }
            return allCategories
        },
        []
    )

    // Sort categories alphabetically
    categories.sort((a, b) => a.title.localeCompare(b.title))

    const articles = activeCategory
        ? data.allContentfulArticle.edges.filter(
              (article) =>
                  article.node.category !== null &&
                  article.node.category.id === activeCategory.id
          )
        : data.allContentfulArticle.edges
    const title = activeCategory ? activeCategory.title : 'Artikelkategorier'
    const body = activeCategory
        ? activeCategory.description.description
        : 'Välj en kategori nedan och lär dig mer om ett ämne du är intresserad av.'

    return (
        <>
            <GatsbySeo
                title="Artiklar"
                description="Artiklarna på Vinologik är fristående texter som ofta handlar om något specifikt inom världen av vin, exempelvis specifika druvor, regioner eller hur olika vinglas påverkar upplevelsen. Artiklarna är korta, intressanta och passar perfekt till ett glas vin!"
                openGraph={{
                    title: 'Artiklar',
                    description:
                        'Artiklarna på Vinologik är fristående texter som ofta handlar om något specifikt inom världen av vin, exempelvis specifika druvor, regioner eller hur olika vinglas påverkar upplevelsen. Artiklarna är korta, intressanta och passar perfekt till ett glas vin!',
                }}
            />
            <Hero
                heading="Artiklar"
                body="Artiklarna på Vinologik är fristående texter som ofta handlar om något specifikt inom världen av vin, exempelvis specifika druvor, regioner eller hur olika vinglas påverkar upplevelsen. <br/> <br/> Artiklarna är korta, intressanta och passar perfekt till ett glas vin!"
            />
            <SectionContainer
                title={title}
                subtitle={body}
                introComponent={
                    <CategoriesList
                        active={activeCategory}
                        categories={categories}
                        large={true}
                        onClick={setCategory}
                    />
                }
            >
                {articles.map(({ node: article }) => {
                    return (
                        <Card
                            key={article.id}
                            to={article.slug}
                            title={article.title}
                            subtitle={'Publicerat ' + article.createdAt}
                            tags={[article.category.title]}
                            bodyText={article.subtitle}
                            image={article.image}
                            showBanner={!!article.quiz}
                            bannerText={'+ quiz'}
                        />
                    )
                })}
            </SectionContainer>
        </>
    )
}

export default ArticlesPage
